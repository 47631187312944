<template>
  <div v-cloak id="sitebody" :class="{ 'mobile': !isDesktop, 'premium': user && user.group === 777 }">
    <!-- <div v-if="isDesktop" v-show="showVideoAd" class="videoroll">
      <div @click="showVideoAd = !showVideoAd">
        <i class="close" />
      </div>
      <div class="body">
        <div id="yandex_rtb_R-A-2270065-4"></div>
      </div>
    </div> -->
    
    <!-- <MovieAds v-if="(!user || user.group === 1)" /> -->

    <Mobile v-if="!isDesktop" />

    <div v-if="isDesktop" class="mainmenu">
      <nuxt-link to="/" class="logotype">
        <span class="f">1x</span>
        <span class="s">kino</span>
      </nuxt-link>
      <Search />
      <div class="profile">
        <!-- <span v-if="user" @click="getFavs">Мои списки</span>
        <nuxt-link v-else :to="`/signin?ref=${encodeURI($route.fullPath)}`">Мои списки</nuxt-link> -->
        <nuxt-link to="/app" tag="div" class="app-btn glow-on-hover">
          <img src="/static_files/icons/download-from-cloud.svg" alt="Скачать приложение 1X">
          В приложении удобнее
        </nuxt-link>
        <nuxt-link v-if="!user" :to="`/signin?ref=${encodeURI($route.fullPath)}`" tag="strong">Войти</nuxt-link>
        <strong v-else @click="showUserMenu = !showUserMenu">Профиль</strong>
      </div>
    </div>
    <div v-else class="mainmenu">
      <Search />
      <div class="profile">
        <div style="text-align:left;">
          <nuxt-link to="/" class="logotype">
            <span class="f">1x</span>
            <span class="s">kino</span>
          </nuxt-link>
        </div>
        <!-- <div style="text-align:center;">
          <span v-if="user" @click="getFavs">
            <img src="/static_files/icons/favourite.svg" alt="">
          </span>
          <nuxt-link v-else :to="`/signin?ref=${encodeURI($route.fullPath)}`">
            <img src="/static_files/icons/favourite.svg" alt="">
          </nuxt-link>
        </div> -->
        <div style="text-align:right;">
          <nuxt-link v-if="!user" :to="`/signin?ref=${encodeURI($route.fullPath)}`" tag="strong">Войти</nuxt-link>
          <strong v-else @click="showUserMenu = !showUserMenu">Профиль</strong>
        </div>
      </div>
    </div>

    <div v-if="showUserMenu" class="user-menu">
      <div @click="showUserMenu = false" class="close"></div>
      <div class="poster">
        <img :src="user.photo" alt="">
      </div>
      <strong>{{ user.email }}</strong>
      <span @click="getFavs">Мои списки</span>

      <!-- <div>
        Баланс: {{ user.balance }}
        <nuxt-link to="/donate">+</nuxt-link>
      </div>
      <div class="prem">
        Premium
        <template v-if="user.group === 777">
          до {{ user.premium_date | filterPremDate }}
          <div>
            <nuxt-link to="/donate">Продлить</nuxt-link>
          </div>
        </template>
        <template v-else>
          не активирован. <nuxt-link to="/donate">Подключить</nuxt-link>
        </template>
      </div> -->
      <span @click="logout">Выйти</span>
    </div>

    <transition name="toLeft">
      <div v-if="userFavsD" class="user-favs">
        <i @click="userFavsD = null" class="close" />

        <div class="menu">
          <div :style="`width:${7 * 120}px;`">
            <div @click="favCurrent = 'like'" :class="{ active: favCurrent === 'like' }" class="item">
              Понравились <sup>{{ userFavsD.likes.length }}</sup>
            </div>
            <div @click="favCurrent = 'planned'" :class="{ active: favCurrent ===  'planned' }" class="item">
              Буду смотреть <sup>{{ userFavsD.favs.filter(el => el.action === 'planned').length }}</sup>
            </div>
            <div @click="favCurrent = 'watching'" :class="{ active: favCurrent ===  'watching' }" class="item">
              Смотрю <sup>{{ userFavsD.favs.filter(el => el.action === 'watching').length }}</sup>
            </div>
            <div @click="favCurrent = 'completed'" :class="{ active: favCurrent ===  'completed' }" class="item">
              Просмотрено <sup>{{ userFavsD.favs.filter(el => el.action === 'completed').length }}</sup>
            </div>
            <div @click="favCurrent = 'hold'" :class="{ active: favCurrent ===  'hold' }" class="item">
              Отложено <sup>{{ userFavsD.favs.filter(el => el.action === 'hold').length }}</sup>
            </div>
            <div @click="favCurrent = 'dropped'" :class="{ active: favCurrent ===  'dropped' }" class="item">
              Брошено <sup>{{ userFavsD.favs.filter(el => el.action === 'dropped').length }}</sup>
            </div>
            <div @click="favCurrent = 'rewatching'" :class="{ active: favCurrent ===  'rewatching' }" class="item">
              Пересматриваю <sup>{{ userFavsD.favs.filter(el => el.action === 'rewatching').length }}</sup>
            </div>
          </div>
        </div>
        <div v-if="userFavs.length" class="body">
          <div v-for="item in userFavs" :key="`fav-${item._id}`" :class="{ active: currentFav === item.id }" class="item">
            <div class="poster">
              <img :src="item.poster" :alt="item.title_ru">
              <nuxt-link :to="`/film/${postUrl(item.id, item.title_ru)}`"></nuxt-link>
            </div>
            <div @click="showIframe(item.id)">
              <strong>{{ item.title_ru }}</strong>
            </div>
            <div v-if="currentFav === item.id" class="iframe">
              <iframe
                :src="`https://finve-as.newplayjj.com:9443/?kp=${item.id}&token=a7b8af4347ca73314bfe2ce7db6cc5`"
                frameBorder="0"
                allow="autoplay *; fullscreen *"
                allowfullscreen
                title="Watch Trailer Online"
              ></iframe>
            </div>
          </div>
        </div>
        <div v-else class="body">
          Ничего не найдено
        </div>
      </div>
    </transition>

    <!-- <template v-if="!user || user.group === 1">
      <div id="yandex_rtb_R-A-2270065-1" class="rsya-block"></div>
      <nuxt-link v-if="!user || (user.group !== 777 && user.group !== 0)" to="/donate" tag="div" class="close-ad">
        Убрать рекламу
      </nuxt-link>
    </template> -->

    <Recommends />

    <div class="content">
      <nuxt />

      <transition :name="`${!isDesktop ? 'toTop' : ''}`">
        <div v-if="$device.isDesktop || showFilters" class="filters">
          <div class="row">
            <strong @click="showSub($event)">
              {{ genre ? genre : 'Жанр'}}
            </strong>
            <div class="sub">
              <div @click="genre = ''">Все</div>
              <div v-for="g in genres" :key="`filters-genre-${g}`" @click="genre = g">
                {{ g }}
              </div>
            </div>
          </div>
          <div class="row">
            <strong @click="showSub($event)">
              {{ year ? year : 'Год'}}
            </strong>
            <div class="sub">
              <div @click="year = ''">Все</div>
              <div v-for="y in years" :key="`filters-year-${y}`" @click="year = y">
                {{ y }}
              </div>
              <div v-for="d in decade" :key="`filters-year-${d}`" @click="year = d">
                {{ d }}
              </div>
            </div>
          </div>
          <div class="row">
            <strong @click="showSub($event)">
              {{ country ? country : 'Страна'}}
            </strong>
            <div class="sub">
              <div @click="country = ''">Все</div>
              <div v-for="с in countries" :key="`filters-countries-${с}`" @click="country = с">
                {{ с }}
              </div>
            </div>
          </div>
          <div class="row">
            <strong @click="showSub($event)">
              {{ sortH ? sortH : 'Сортировка'}}
            </strong>
            <div class="sub">
              <div @click="sort = 'date'">Самые новые</div>
              <div @click="sort = 'imdb_rating'">Рейтинг ImDB</div>
              <div @click="sort = 'kinopoisk_rating'">Рейтинг КиноПоиск</div>
            </div>
          </div>

          <div class="row with-trailer">
            <span>Есть трейлер</span>
            <input v-model="existsTrailer" type="checkbox" id="cbx" style="display:none" />
            <label for="cbx" class="toggle"><span></span></label>
          </div>

          <div v-if="!isDesktop" style="margin-top: 20px; text-align: center;">
            <button @click="showFilters = false">
              Применить
            </button>
          </div>
        </div>
      </transition>

      <div v-if="!isDesktop" @click="showFilters = !showFilters" class="btn-filters">
        <img src="/static_files/icons/filters-dark.svg" alt="">
        Фильтры
      </div>
    </div>

    <footer class="footer">
      <div class="links">
        <a href="https://1xgroup.ru" target="_blank">1XGroup</a>
        <a href="mailto:support@1xgroup.ru" target="_blank">
          support@1xgroup.ru
        </a>
        <!-- <a href="#" target="_blank">
          ВКонтакте
        </a>
        <a href="#" target="_blank">
          Телеграм
        </a> -->
      </div>
    </footer>

    <transition name="toBottom">
      <Error v-if="error" :error="error" />
    </transition>

    <FeedbackComponent v-if="feedback" />

    <i onclick="window.scrollTo(0,0)" class="scrollup" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import global from '~/mixins/global.js'

Vue.mixin(global)

export default {
  components: {
    Search: () => import('~/components/Search.vue'),
    Error: () => import('~/components/Error.vue'),
    Mobile: () => import('~/components/Mobile.vue'),
    FeedbackComponent: () => import('~/components/FeedbackComponent.vue'),
    Recommends: () => import('~/components/Recommends.vue'),
    // MovieAds: () => import('~/components/MovieAds.vue'),
  },
  filters: {
    filterPremDate(val) {
      const d = new Date(val)
      const day = d.getDate()
      const month = d.getMonth() + 1
      const year = d.getFullYear()
      const hour = d.getHours()
      const min = d.getMinutes()

      return `${day}.${month}.${year} ${hour}:${min}`
    },
  },
  watch: {
    favCurrent(val) {
      if (!this.userFavsD) return
      if (val !== 'like') {
        this.userFavs = this.userFavsD.favs.filter(el => el.action === val)
      } else {
        this.userFavs = this.userFavsD.likes
      }
    },
    userFavsD(val) {
      if (!val) {
        this.favCurrent = 'like'
        this.userFavs = []
      }
    },
    changeRoute() {
//       if (!this.isDesktop && (!this.user || this.user.group === 1)) {
//         if (this.showFS) return
//         const rsyaMobileFS = document.createElement('script')
//         rsyaMobileFS.innerHTML = `
// window.yaContextCb.push(()=>{
//   Ya.Context.AdvManager.render({
//     type: 'fullscreen',
//     blockId: 'R-A-2270065-3'
//   })
// })`
//         document.body.appendChild(rsyaMobileFS)
//         this.showFS = true
//       }

      this.userFavsD = null
      this.userFavs = []

      document.body.style.overflow = ''

      setTimeout(() => {
        this.lazyLoadImgs()
      }, 400)
    },
    genre() {
      this.getData()
    },
    year() {
      this.getData()
    },
    country() {
      this.getData()
    },
    existsTrailer() {
      this.getData()
    },
    sort() {
      if (this.sort === 'date') {
        this.sortH = 'Самые новые'
      } else if (this.sort === 'imdb_rating') {
        this.sortH = 'Самые ImDB'
      } else if (this.sort === 'kinopoisk_rating') {
        this.sortH = 'Рейтинг КиноПоиск'
      }
      this.getData()
    },
  },
  computed: {
    ...mapState(['error', 'feedback', 'isDesktop', 'user']),
    changeRoute() {
      return this.$route.fullPath
    },
  },
  data() {
    const years = []
    for(let i = new Date().getFullYear(); i > 2015; i--) {
      years.push(i)
    }

    return {
      showFilters: false,
      favCurrent: 'like',
      userFavs: [],
      userFavsD: null,
      showAd: false,
      showFS: false,
      showVideoAd: false,
      showUserMenu: false,
      currentFav: null,
      // ---
      existsTrailer: false,
      years,
      genre: '',
      year: '',
      country: '',
      sort: '',
      sortH: 'Самые новые',
      // sortH: sort === 'imdb_rating'
      //   ? 'Рейтинг ImDB'
      //   : sort === 'kinopoisk_rating'
      //     ? 'Рейтинг КиноПоиск'
      //     : 'Самые новые',
      decade: [
        '2015-2011',
        '2010-2000',
        '2000-1990',
        '1990-1980',
        '1980-1970',
        '1970-1960'
      ],
      genres: [
        'боевик',
        'военный',
        'драма',
        'комедия',
        'музыка',
        'семейный',
        'фантастика',
        'мультфильм',
        'фэнтези',
        'мелодрама',
        'аниме',
        'детектив',
        'криминал',
        'триллер',
        'приключения',
        'вестерн',
        'история',
        'короткометражка',
        'документальный',
        'реальное ТВ',
        'ужасы',
        'спорт',
        'биография',
        'ток-шоу',
        'мюзикл'
      ],
      countries: [
        'ОАЭ',
        'Франция',
        'Китай',
        'Япония',
        'США',
        'Австрия',
        'Германия',
        'Кипр',
        'Ливан',
        'Россия',
        'Дания',
        'Корея Южная',
        'Турция',
        'Гонконг',
        'Великобритания',
        'Бельгия',
        'Люксембург',
        'Индия',
        'Канада',
        'Казахстан',
        'Италия',
        'Аргентина',
        'Испания',
        'Украина',
        'Бразилия',
        'Польша'
      ],
    }
  },
  mounted() {
    // this.showAds()

    // this.lazyLoadImgs()

    // window.addEventListener('scroll', this.lazyLoadImgs)

    window.addEventListener('scroll', this.scrollTop)

    window.addEventListener('keyup', this.switchFavs)

    if (this.isDesktop) {
      window.addEventListener('scroll', this.stickyFilters)
    }


    if (this.$route.query) {
      const query = this.$route.query

      if (query.genre) this.genre = query.genre
      if (query.country) this.country = query.country
      if (query.year) this.year = query.year
    }
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.switchFavs)

    if (this.isDesktop) {
      window.removeEventListener('scroll', this.stickyFilters)
    }
  },
  methods: {
    getData() {
      // if (this.loading) return
      // this.loading = true
      const genre = this.genre ? this.genre : ''
      const year = this.year ? this.year : ''
      const country = this.country ? this.country : ''
      const sort = this.sort ? this.sort : ''
      let url = `/?page=0` // `/api/catalog?page=0`
      if (genre) url += `&genre=${encodeURIComponent(genre)}`
      if (year) url += `&year=${encodeURIComponent(year)}`
      if (country) url += `&country=${encodeURIComponent(country)}`
      if (sort) url += `&sort=${encodeURIComponent(sort)}`
      if (this.existsTrailer) url += `&trailer=${this.existsTrailer}`

      const elList = document.querySelectorAll('.filters .row .sub')
      elList.forEach(el => (el.style.display = 'none'))

      this.$router.push(url)
    },
    stickyFilters() {
      const elem = document.querySelector('.filters')
      if (!elem) return

      const leftPos = elem.getBoundingClientRect().left

      if (window.scrollY > 400) {
        elem.style.position = 'fixed'
        elem.style.top = '70px'
        elem.style.left = leftPos + 'px'
      }
      else {
        elem.style.position = 'relative'
        elem.style.top = '0px'
        elem.style.left = '0px'
      }
    },
    showSub(e) {
      const el = e.target.nextElementSibling
      if (el.style.display === 'block') {
        el.style.display = 'none'
      } else {
        const elList = document.querySelectorAll('.filters .row .sub')
        elList.forEach(el => (el.style.display = 'none'))
      
        el.style.display = 'block'
      }
    },
    // ---
    showIframe(kpid) {
      if (this.currentFav === kpid) {
        this.currentFav = null
      } else {
        this.currentFav = kpid
      }
    },
    switchFavs(event) {
      if (event.keyCode === 27) {
        this.userFavsD = null
        this.userFavs = []
      }
    },
    async getFavs() {
      const { data } = await this.$axios.get(`/api/user/favs`)
      // console.log('data', data)
      this.userFavsD = data
      this.userFavs = data.likes
    },
    scrollTop () {
      if (this.$route.name === 'comics-id-chapter-ch') return
      const elem = document.querySelector('.scrollup')
      if (document.documentElement.scrollTop > 1000) {
        elem.classList.add('sticky')
      } else {
        elem.classList.remove('sticky')
      }
    },
    showAds() {
      if (!this.user || this.user.group === 1) {
        setInterval(() => {
          this.showVideoAd = true
        }, 5 * 60 * 1000)

        this.showVideoAd = true
        
        const rsya1 = document.createElement('script')
        rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
        document.head.appendChild(rsya1)

        const rsya2 = document.createElement('script')
        rsya2.src = `https://yandex.ru/ads/system/context.js`
        rsya2.async = true
        document.head.appendChild(rsya2)

        setTimeout(() => {
          const rsyaHorizon = document.createElement('script')
          rsyaHorizon.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-2270065-1',
      blockId: 'R-A-2270065-1'
    })
    })`
          document.body.appendChild(rsyaHorizon)

          if (!this.isDesktop) {
            const rsyaFooterMobile = document.createElement('script')
            rsyaFooterMobile.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      type: 'floorAd',
      blockId: 'R-A-2270065-2'
    })
    })`
            document.body.appendChild(rsyaFooterMobile)
          } else {
            const rsyaDesktopVideoAd = document.createElement('script')
            rsyaDesktopVideoAd.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-2270065-4',
      blockId: 'R-A-2270065-4'
    })
    })`
            document.body.appendChild(rsyaDesktopVideoAd)
          }
        }, 200)
      }
    },
    async logout() {
      await this.$axios.get('/api/auth/logout')
      this.$store.commit('setUser', null)
      location.reload()
    },
  }
}
</script>
