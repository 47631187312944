import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8a152156 = () => interopDefault(import('../pages/32rf21/index.vue' /* webpackChunkName: "pages/32rf21/index" */))
const _db52225e = () => interopDefault(import('../pages/app.vue' /* webpackChunkName: "pages/app" */))
const _e44d5912 = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _021f80ee = () => interopDefault(import('../pages/popular.vue' /* webpackChunkName: "pages/popular" */))
const _1892fe90 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _4dbf9fa2 = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _4e81e7b3 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _b104f0ae = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _9195047e = () => interopDefault(import('../pages/32rf21/comments.vue' /* webpackChunkName: "pages/32rf21/comments" */))
const _7ff22b44 = () => interopDefault(import('../pages/32rf21/desc.vue' /* webpackChunkName: "pages/32rf21/desc" */))
const _1fe05ad2 = () => interopDefault(import('../pages/32rf21/feedback.vue' /* webpackChunkName: "pages/32rf21/feedback" */))
const _62e8f06c = () => interopDefault(import('../pages/32rf21/kino.vue' /* webpackChunkName: "pages/32rf21/kino" */))
const _72d4c55e = () => interopDefault(import('../pages/profile/favs.vue' /* webpackChunkName: "pages/profile/favs" */))
const _0dc39702 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _323c3172 = () => interopDefault(import('../pages/film/_id/index.vue' /* webpackChunkName: "pages/film/_id/index" */))
const _5317d836 = () => interopDefault(import('../pages/name/_id.vue' /* webpackChunkName: "pages/name/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/32rf21",
    component: _8a152156,
    name: "32rf21"
  }, {
    path: "/app",
    component: _db52225e,
    name: "app"
  }, {
    path: "/copyright",
    component: _e44d5912,
    name: "copyright"
  }, {
    path: "/popular",
    component: _021f80ee,
    name: "popular"
  }, {
    path: "/privacy",
    component: _1892fe90,
    name: "privacy"
  }, {
    path: "/signin",
    component: _4dbf9fa2,
    name: "signin"
  }, {
    path: "/success",
    component: _4e81e7b3,
    name: "success"
  }, {
    path: "/termsofuse",
    component: _b104f0ae,
    name: "termsofuse"
  }, {
    path: "/32rf21/comments",
    component: _9195047e,
    name: "32rf21-comments"
  }, {
    path: "/32rf21/desc",
    component: _7ff22b44,
    name: "32rf21-desc"
  }, {
    path: "/32rf21/feedback",
    component: _1fe05ad2,
    name: "32rf21-feedback"
  }, {
    path: "/32rf21/kino",
    component: _62e8f06c,
    name: "32rf21-kino"
  }, {
    path: "/profile/favs",
    component: _72d4c55e,
    name: "profile-favs"
  }, {
    path: "/",
    component: _0dc39702,
    name: "index"
  }, {
    path: "/film/:id",
    component: _323c3172,
    name: "film-id"
  }, {
    path: "/name/:id?",
    component: _5317d836,
    name: "name-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
