<template>
  <main class="index">
    <!-- <LastComments v-if="lastComments && lastComments.length" :comments="lastComments" /> -->

    <div class="comics">
      <h1>
        Смотреть кино онлайн{{
          genre ? '. ' + genre.slice(0, 1).toUpperCase() + genre.slice(1) : ''
        }}{{
          country ? '. ' + country.slice(0, 1).toUpperCase() + country.slice(1) : ''
        }}{{
          year ? ' (' + year + ')' : ''
        }}{{
          sort
            ? sort === 'date'
              ? '. Самые новые'
              : sort = 'imdb_rating'
                ? '. Рейтинг ImDB'
                : sort = 'kinopoisk_rating'
                  ? '. Рейтинг КиноПоиск'
                  : ''
            : ''
        }}
      </h1>

      <div class="total">
        Найдено: {{ total }}
      </div>
      <Items :items="items" />
      <div class="more" />

      <div class="description">
        <template v-if="genre && year && country">
          <p>Добро пожаловать на наш сайт! Здесь вы можете смотреть фильмы и сериалы онлайн бесплатно в жанре {{ genre }}, выпущенные в {{ year }} году и производства {{ country }}.</p>

          <p>Ищете захватывающие приключения, напряженные схватки и неожиданные повороты сюжета? Тогда вы попали в нужное место! На нашем сайте представлен широкий выбор самых популярных, которые не оставят вас равнодушными.</p>

          <p>Забудьте о неудобствах и ограничениях, связанных с традиционными способами просмотра. Мы предоставляем удобный и легкий доступ к вашим любимым сериалам прямо из уютного домашнего кресла. Никакой регистрации, никаких платных подписок - только качественный контент без ограничений.</p>

          <p>Наши фильмы и сериалы в жанре {{ genre }} отличаются захватывающим сюжетом, кинематографичной постановкой и выдающимися актерскими исполнениями. Будьте готовы к адреналиновым взрывам и эмоциональным горкам, которые заставят вас верить в героев и жить с ними каждый момент их путешествия.</p>

          <p>Смотрите любимые фильмы и сериалы в любое время, удобное именно для вас. Наш сайт доступен на всех устройствах, от компьютеров до мобильных телефонов, чтобы вы могли наслаждаться просмотром где угодно и когда угодно.</p>

          <p>Не упустите возможность окунуться в захватывающий мир жанра {{ genre }} {{ year }} года, созданных лучшими творческими командами {{ country }}. Присоединяйтесь к нам и погрузитесь в увлекательные истории, которые запомнятся на долгое время!</p>

          <p>Итак, что же вы ждете? Выбирайте сериал, запасайтесь попкорном и наслаждайтесь просмотром! Смотреть фильмы и сериалы онлайн бесплатно в жанре {{ genre }} - это легко и увлекательно, только на нашем сайте.</p>
        </template>
        <template v-if="year">
          <p>Добро пожаловать на наш сайт! Здесь вы можете смотреть лучшие фильмы и сериалы онлайн абсолютно бесплатно. Мы предлагаем огромный выбор сериалов, выпущенных в {{ year }} году, чтобы удовлетворить любой вкус и предпочтение. Насладитесь захватывающими сюжетами, увлекательными персонажами и великолепной игрой актеров, не покидая уют домашнего дивана!</p>

          <p>Наш сайт обеспечивает простой и удобный интерфейс, позволяющий легко находить и начинать просмотр желаемых сериалов всего в несколько кликов. Мы постоянно обновляем нашу библиотеку, чтобы предоставлять вам самые свежие и актуальные фильмы и сериалы, выпущенные в {{ year }} году.</p>

          <p>Независимо от вашего предпочтения — драмы, комедии, фантастики, криминальные истории или романтические приключения, у нас вы обязательно найдете что-то, что зацепит ваше внимание и оставит яркие впечатления.</p>

          <p>Не тратьте время на поиск качественных сериалов в сети — забудьте о рекламе и ограничениях. Присоединяйтесь к нам прямо сейчас и окунитесь в захватывающий мир кинематографа {{ year }} года! Смотрите фильмы и сериалы онлайн бесплатно и наслаждайтесь незабываемым просмотром!</p>
        </template>
        <template v-if="country">
          <p>Добро пожаловать на наш сайт! Здесь вы можете смотреть бесплатно онлайн лучшие фильмы и сериалы из {{ country }}. Откройте для себя захватывающие истории, захватывающие сюжеты и великолепную актерскую игру прямо у вас дома. Мы предоставляем широкий выбор сериалов различных жанров, чтобы удовлетворить вкусы каждого зрителя.</p>

          <p>На нашем сайте вы можете наслаждаться просмотром сериалов в удобное для вас время, без необходимости скачивать или регистрироваться. Наша коллекция постоянно обновляется, чтобы вы всегда могли найти что-то интересное и новое. Погрузитесь в мир качественного кинематографа {{ country }} и проведите время с удовольствием, следуя за увлекательными сюжетами и любимыми персонажами.</p>

          <p>Смотрите свои любимые фильмы и сериалы на русском языке с субтитрами, чтобы углубиться в атмосферу произведений и наслаждаться всеми нюансами производства {{ country }}. Не упустите возможность путешествовать в увлекательные миры истории, романтики, драмы, комедии и многого другого.</p>

          <p>Присоединяйтесь к нам и наслаждайтесь просмотром сериалов онлайн бесплатно прямо сейчас! Погрузитесь в мир кино {{ country }} вместе с нами.</p>
        </template>
        <template v-else>
          <p>Добро пожаловать на наш сайт! У нас вы можете смотреть фильмы и сериалы онлайн бесплатно!</p>

          <p>Наши коллекции насыщены захватывающими сериалами в самых разнообразных жанрах: от драм и триллеров до комедий и фантастики. Независимо от вашего вкуса, у нас всегда найдется что-то интересное для просмотра.</p>

          <p>Мы предлагаем удобный и интуитивно понятный интерфейс, который позволяет быстро находить и начинать просмотр любимых сериалов. Наша оптимизированная платформа обеспечивает высокое качество видео и бесперебойную загрузку, что делает просмотр максимально комфортным.</p>

          <p>Забудьте о необходимости скачивать тяжелые файлы или тратить время на поиск свежих серий в сети. На нашем сайте вы всегда найдете актуальные серии популярных сериалов в удобное для вас время.</p>

          <p>Присоединяйтесь к нашему сообществу ценителей кино, чтобы делиться впечатлениями, обсуждать сюжеты и быть в курсе всех новинок в мире телевизионных шоу.</p>

          <p>Наслаждайтесь просмотром любимых фильмов и сериалов без лишних хлопот и ограничений! Приятного просмотра на нашем сайте!</p>
        </template>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  components: {
    Items: () => import('~/components/Items.vue'),
    // LastComments: () => import('~/components/LastComments.vue'),
  },
  head() {
    const title = `Смотреть онлайн фильмы и сериалы ${
      this.genre ? ' жанра ' + this.genre : ''
    }${
      this.country ? '. ' + this.country : ''
    }${
      this.year ? ' (' + this.year + ')' : ''
    } бесплатно на русском в хорошем HD качестве`
    const fullPath = `https://1xkino.ru${
      this.genre || this.country || this.year
        ? `?page=${this.page}${
          this.genre ? `&genre=${this.genre}` : ''
        }${
          this.country ? `&country=${this.country}` : ''
        }${
          this.year ? `&year=${this.year}` : ''
        }`
        : ''
    }`
    return {
      title: title,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: fullPath
        }
      ],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `смотреть фильмы и сериалы бесплатно на русском`
        },
        {
          hid: 'description',
          name: 'description',
          content: `На нашем сайте вы можете бесплатно смотреть онлайн фильмы и сериалы на русском и с хорошим HD качеством видео${
      this.genre ? '. ' + this.genre : ''
    }${
      this.country ? '. ' + this.country : ''
    }${
      this.year ? ' (' + this.year + ')' : ''
    }`
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: fullPath
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: title
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `На нашем сайте вы можете бесплатно смотреть онлайн фильмы и сериалы на русском и с хорошим качеством видео${
      this.genre ? '. ' + this.genre : ''
    }${
      this.country ? '. ' + this.country : ''
    }${
      this.year ? ' (' + this.year + ')' : ''
    }`
        },
      ]
    }
  },
  watchQuery: ['genre', 'year', 'country', 'sort', 'trailer'],
  async asyncData({ $axios, query, error }) {
    const qArr = ['genre', 'year', 'country', 'sort', 'trailer']

    if (Object.keys(query).length) {
      if (
        !Object.keys(query).filter(key => {
          return qArr.includes(key) && query[key]
        }).length
      ) {
        return error({ statusCode: 404, message: 'Not found' })
      }
    }

    const genre = query.genre ? query.genre : ''
    const year = query.year ? query.year : ''
    const country = query.country ? query.country : ''
    const sort = query.sort ? query.sort : ''
    const trailer = query.trailer
    let url = `/api/index?page=0`
    if (genre) url += `&genre=${encodeURIComponent(genre)}`
    if (year) url += `&year=${encodeURIComponent(year)}`
    if (country) url += `&country=${encodeURIComponent(country)}`
    if (sort) url += `&sort=${encodeURIComponent(sort)}`
    if (trailer) url += `&trailer=${trailer}`

    const { data } = await $axios.get(url)
    
    return {
      ...data,
      page: 0,
      loading: false,
      genre,
      year,
      country,
      sort,
      trailer,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.showMore)

    const slider = document.querySelector('.slider-wrapper')
    if (slider) slider.addEventListener('scroll', this.lazyLoadImgs)

  //   if (this.$device.isDesktop) {
  //     setTimeout(() => {
  //       const rsyaVertical = document.createElement('script')
  //       rsyaVertical.innerHTML = `
  // window.yaContextCb.push(()=>{
  // Ya.Context.AdvManager.render({
  //   renderTo: 'yandex_rtb_R-A-2270065-5',
  //   blockId: 'R-A-2270065-5'
  // })
  // })`
  //       document.body.appendChild(rsyaVertical)
  //     }, 200)
  //   }
  },
  beforeDestroy() {
    const slider = document.querySelector('.slider-wrapper')
    if (slider) slider.removeEventListener('scroll', this.lazyLoadImgs)

    window.removeEventListener('scroll', this.showMore)
  },
  methods: {
    async showMore() {
      if (this.loading) return
      const elem = document.querySelector('.more')
      if (!elem) return
      const doc = document.documentElement.clientHeight
      const visible = doc - elem.getBoundingClientRect().top
      if (visible > -(doc / 2)) {
        this.loading = true
        const genre = this.genre ? this.genre : ''
        const year = this.year ? this.year : ''
        const country = this.country ? this.country : ''
        const trailer = this.trailer ? this.trailer : ''
        let url = `/api/catalog?page=${++this.page}`
        if (genre) url += `&genre=${encodeURIComponent(genre)}`
        if (year) url += `&year=${encodeURIComponent(year)}`
        if (country) url += `&country=${encodeURIComponent(country)}`
        if (trailer) url += `&trailer=true`
        const { data } = await this.$axios.get(url)
        const items = this.items.concat(data.items)
        this.items = items
        this.loading = false
      }
    },
  }
}
</script>

<style src="~/assets/index.styl" lang="stylus" />
